<template>
  <TableActionsComponent
    :editing="editing"
    :commentValue="commentValue"
    :noQuickEdit="noQuickEdit"
    :noViewEdit="noViewEdit"
    :noDropdown="noDropdown"
    @cancel="$emit('cancel')"
    @validate="$emit('validate')"
    @comment="$emit('comment')"
    @view="$emit('view')"
    @edit="$emit('edit')"
  >
    <b-dropdown-item
      class="d-block d-md-none"
      :disabled="item.emblavements.length === 0"
      @click="$emit('view')"
    >
      <b-icon-eye></b-icon-eye>
      Visualiser l'emblavement
    </b-dropdown-item>
    <b-dropdown-item class="d-block d-md-none" @click="$emit('edit')">
      <b-icon-pencil-square></b-icon-pencil-square>
      Modifier l'emblavement
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('dupliquer')">
      <b-icon-files></b-icon-files>
      Dupliquer l'emblavement
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('semer')">
      <b-icon-check-all></b-icon-check-all>
      J'ai sem&eacute; tout...
    </b-dropdown-item>
    <b-dropdown-item
      :disabled="item.etatParcelle !== 'SEMEE'"
      @click="$emit('retourner')"
    >
      <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
      Retourner la parcelle
    </b-dropdown-item>
  </TableActionsComponent>
</template>

<script>
import TableActionsComponent from "./TableActionsComponent.vue";
export default {
  name: "EmblavementsActionsComponent",
  components: {
    TableActionsComponent,
  },
  /**
   * item = objet parcelle
   * editing = true pour basculer en mode édition (false par défaut)
   * commentValue = valeur du commentaire (s'affiche au survol)
   * noQuickEdit = true pour masquer les boutons d'édition en ligne
   * noViewEdit = true pour masquer le bouton view/edit
   * noDropdown = true pour masquer le menu dropdown
   */
  props: [
    "item",
    "editing",
    "commentValue",
    "noQuickEdit",
    "noViewEdit",
    "noDropdown",
  ],
};
</script>
