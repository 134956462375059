<template>
  <HelpComponent>
    <b-row>
      <b-col>
        Cet &eacute;cran vous permet d'indiquer &agrave; votre
        coop&eacute;rative :
        <ul>
          <li>
            l'assolement que vous pr&eacute;voyez pour la collecte &agrave;
            venir
          </li>
          <li>les modifications d'assolement pour la collecte en cours</li>
        </ul>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <strong>
          <u>Rappel :</u> la pr&eacute;cision et la fiabilit&eacute; de ces
          informations ont un impact significatif sur l'activit&eacute;
          commerciale de votre coop&eacute;rative, et donc le prix d'achat de
          vos productions !
        </strong>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Dans certains cas, le produit que vous aurez saisi sera automatiquement
        remplac&eacute; &agrave; l'enregistrement. Si vous pensez qu'il y a
        erreur, n'h&eacute;sitez pas &agrave; en parler &agrave; votre
        conseiller.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Dans le cas o&ugrave; vous n'&ecirc;tes pas encore fix&eacute; sur une
        vari&eacute;t&eacute;, vous pouvez saisir "AUTRE VARIETE" mais n'oubliez
        pas de revenir la pr&eacute;ciser lorsque vous aurez re&ccedil;u vos
        semences !
      </b-col>
    </b-row>
    <WarningBloc>
      Une modification dans cet onglet peut avoir un impact sur
      l'autoconsommation et/ou les enl&egrave;vements. Pensez donc &agrave;
      v&eacute;rifier syst&eacute;matiquement ces deux onglets lorsque vous
      faites des modifications d'assolement !
    </WarningBloc>
  </HelpComponent>
</template>

<script>
import HelpComponent from "./HelpComponent.vue";
import WarningBloc from "../blocs/WarningBloc.vue";
export default {
  name: "HelpEmblavementsComponent",
  components: { HelpComponent, WarningBloc },
};
</script>
