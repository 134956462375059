<template>
  <CustomSelectComponent
    ref="select"
    :label="label"
    :labelCols="labelCols"
    :readOnly="readOnly"
    :readOnlyValue="readOnlyValue"
    :disabled="disabled"
    :rules="rules"
    :options="options"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></CustomSelectComponent>
</template>

<script>
import CustomSelectComponent from "./CustomSelectComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectCultureComponent",
  components: { CustomSelectComponent },
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // true pour ajouter une option "Toutes" (false par défaut)
    all: [Boolean, String],
    // propriété label du form-group
    label: {
      type: String,
      default: "Culture",
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  watch: {
    value: "load",
    all: "load",
  },
  data: () => ({
    options: [],
  }),
  computed: {
    readOnlyValue() {
      let culture = this.$store.getters["decl/culture"](this.value);
      return culture
        ? `${culture.libelleProduit} ${culture.libelleVariete}`
        : this.value;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let options = this.$store.state.decl.cultures
        // Filtrer les cultures qui n'ont plus de parcelle rattachée
        .filter(
          (c) =>
            !!this.$store.state.decl.parcelles.find((p) => p.idCulture === c.id)
        )
        // Transformer les autres en options
        .map((c) => {
          return {
            value: c.id,
            text: `${c.libelleProduit} ${c.libelleVariete}`,
          };
        })
        // Les trier par texte
        .sort(UtilsService.sortByStringProperty("text"));

      // Ajout de l'option courante désactivée si nécessaire
      if (this.value && !options.find((o) => o.value === this.value)) {
        let culture = this.$store.getters["decl/culture"](this.value);
        options = [
          {
            value: this.value,
            text: culture
              ? `${culture.libelleProduit} ${culture.libelleVariete}`
              : "INCONNUE",
            disabled: true,
          },
          ...options,
        ];
      }

      // Ajout de l'option Toutes si nécessaire
      if (this.all === true || this.all === "true") {
        options = [{ value: null, text: "Toutes" }, ...options];
      }

      // SINON si pas de valeur mais une seule option non nulle, sélection automatique
      else if (!this.value && options.length === 1 && options[0].value) {
        await this.$nextTick();
        this.$emit("input", options[0].value);
      }

      this.options = options;
    },
    async validate() {
      await this.$refs.select.validate();
    },
  },
};
</script>
