<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>Retourner ma parcelle</template>
    <b-container v-if="parcelle" class="context-container">
      <GenericReadonlyComponent label="Parcelle :" labelCols="auto">
        {{ parcelle.ilot.libelle }} - {{ parcelle.libelle }} (<MontantComponent
          :value="parcelle.surfaceEmblavee - parcelle.surfaceRetiree"
        ></MontantComponent>
        ha)
      </GenericReadonlyComponent>
    </b-container>
    <template v-if="parcelle">
      <b-form-group
        label="Parcelle retournée en totalité : "
        label-cols="auto"
        label-cols-md="6"
      >
        <b-form-checkbox switch v-model="totalement">
          {{ totalement ? "oui" : "non" }}
        </b-form-checkbox>
      </b-form-group>
      <CustomInputNumberComponent
        v-if="!totalement"
        ref="surface"
        label="Surface à retourner :"
        :labelCols="{ md: 6 }"
        step="0.1"
        append="ha"
        :rules="`required|min_value:0|max_value:${
          parcelle.surfaceEmblavee - parcelle.surfaceRetiree
        }`"
        v-model="surfaceRetournee"
      ></CustomInputNumberComponent>
      <InputCommentaireComponent
        label="Commentaire retournement :"
        :labelCols="{ md: 6 }"
        v-model="commentaire"
      >
        <em>
          Utilisez cet espace pour expliquer bri&egrave;vement pourquoi la
          parcelle a &eacute;t&eacute; retourn&eacute;e.
        </em>
      </InputCommentaireComponent>
      <NoteBloc>
        Vous devrez par la suite d&eacute;clarer un nouvel emblavement sur
        <span v-if="!totalement">la partie retourn&eacute;e de</span> la
        parcelle. <br />
        Si vous ne pr&eacute;voyez pas de resemer sur cette parcelle, utilisez
        la "Surface abandonn&eacute;e" de l'onglet "Parcelles" !
      </NoteBloc>
    </template>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import MontantComponent from "../MontantComponent.vue";
import GenericReadonlyComponent from "../inputs/GenericReadonlyComponent.vue";
import CustomInputNumberComponent from "../inputs/CustomInputNumberComponent.vue";
import InputCommentaireComponent from "../inputs/InputCommentaireComponent.vue";
import NoteBloc from "../blocs/NoteBloc.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "ParcelleFlipDialog",
  components: {
    MontantComponent,
    GenericReadonlyComponent,
    CustomInputNumberComponent,
    InputCommentaireComponent,
    NoteBloc,
  },
  data: () => ({
    id: `parcelle-${uuidv4()}`,
    totalement: true,
    surfaceRetournee: 0.0,
    commentaire: null,
    parcelle: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show(options) {
      this.parcelle = UtilsService.deepCopy(options.parcelle);
      this.totalement = true;
      this.surfaceRetournee =
        this.parcelle.surfaceEmblavee - this.parcelle.surfaceRetiree;
      this.commentaire = null;
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      if (!this.totalement) {
        await this.$refs.surface.validate();
      }
      this.$refs["modal"].hide();
      this.resolvePromise({
        idParcelle: this.parcelle.id,
        surfaceRetournee: this.totalement ? null : this.surfaceRetournee,
        commentaire: this.commentaire,
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
