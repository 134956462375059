<template>
  <b-card class="mobile-card" header-bg-variant="light">
    <template #header>
      {{ item.libelleParcelle }} (<MontantComponent
        :value="surfaceCultivee"
      ></MontantComponent>
      ha)
    </template>
    <b-container
      @click="$emit(item.emblavements.length <= 0 ? 'edit' : 'view')"
    >
      <b-row align-v="center">
        <b-col align-self="stretch">
          <template v-if="item.culture">
            <div>
              {{ item.culture.libelleProduit }}
              <MelangeIcon v-if="item.culture.isMelangeEspeces"></MelangeIcon>
            </div>
            <div v-if="!item.culture.isMelangeEspeces" class="font-down">
              {{ item.culture.libelleVariete }}
              <MelangeIcon v-if="item.culture.isMelangeVarietes"></MelangeIcon>
            </div>
            <!--
              <section v-for="emb in item.emblavements" :key="emb.id">
                <b-card-text>{{ emb.produit.libelle }}</b-card-text>
                <b-card-sub-title>{{ emb.variete.libelle }}</b-card-sub-title>
              </section>
            -->
          </template>
          <template v-else>
            <AnomalieIcon
              v-if="item.anomalies.includes('EMBLAVEMENT_NON_SAISI')"
              :anomaliesParcelle="['EMBLAVEMENT_NON_SAISI']"
            ></AnomalieIcon>
            <b-link href="#" @click="$emit('edit')">
              Saisir un emblavement
            </b-link>
          </template>
        </b-col>
        <b-col
          cols="auto"
          class="px-0 text-center"
          style="width: 60px"
          @click.stop
        >
          <b-form-checkbox
            switch
            :checked="item.etatSemis"
            :disabled="
              item.emblavements.length <= 0 ||
              (item.etatParcelle !== 'INTENTION' &&
                item.etatParcelle !== 'SEMEE')
            "
            @change="$emit('etatSemisChanged')"
          >
          </b-form-checkbox>
          <span class="font-down">
            <template v-if="item.etatSemis">Sem&eacute;&nbsp;</template>
            <template v-else>Non sem&eacute;</template>
          </span>
        </b-col>
        <b-col cols="auto" class="px-0" @click.stop>
          <EmblavementsActionsComponent
            :item="item"
            @view="$emit('view')"
            @edit="$emit('edit')"
            @dupliquer="$emit('dupliquer')"
            @semer="$emit('semer')"
            @retourner="$emit('retourner')"
          ></EmblavementsActionsComponent>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
import AnomalieIcon from "../controls/AnomalieIcon.vue";
import EmblavementsActionsComponent from "../controls/EmblavementsActionsComponent.vue";
export default {
  name: "EmblavementCard",
  components: { MontantComponent, AnomalieIcon, EmblavementsActionsComponent },
  props: ["item"],
  computed: {
    surfaceCultivee() {
      return (
        (this.item?.surfaceEmblavee ?? 0.0) - (this.item?.surfaceRetiree ?? 0.0)
      );
    },
  },
};
</script>
