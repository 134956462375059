<template>
  <GenericReadonlyComponent
    v-if="computedReadOnly"
    :label="label"
    :labelCols="labelCols"
    :quickEdit="quickEdit"
    @edit="$emit('edit')"
    :value="computedReadOnlyValue"
  >
    <slot></slot>
  </GenericReadonlyComponent>
  <UnvalidatedSelectComponent
    v-else-if="rules == null"
    ref="unvalidatedSelect"
    :label="label"
    :labelCols="labelCols"
    :description="description"
    :disabled="disabled"
    :options="options"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></UnvalidatedSelectComponent>
  <ValidatedSelectComponent
    v-else
    ref="validatedSelect"
    :label="label"
    :labelCols="labelCols"
    :description="description"
    :disabled="disabled"
    :rules="rules"
    :options="options"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></ValidatedSelectComponent>
</template>

<script>
import GenericReadonlyComponent from "./GenericReadonlyComponent.vue";
import UnvalidatedSelectComponent from "./UnvalidatedSelectComponent.vue";
import ValidatedSelectComponent from "./ValidatedSelectComponent.vue";
export default {
  name: "CustomSelectComponent",
  components: {
    GenericReadonlyComponent,
    UnvalidatedSelectComponent,
    ValidatedSelectComponent,
  },
  props: {
    // valeur du select (type sans importance)
    value: undefined,
    // valeur pour l'affichage lecture seule
    readOnlyValue: [String, Function],
    // tableau des options { value: ... , text: ... }
    options: {
      type: Array,
      required: true,
    },
    // propriété label du form-group
    label: {
      type: String,
      required: true,
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété description du form group
    description: String,
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour autoriser l'édition rapide (false par défaut)
    quickEdit: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  computed: {
    computedReadOnly() {
      return this.readOnly === true || this.readOnly === "true";
    },
    computedReadOnlyValue() {
      if (typeof this.readOnlyValue === "function") {
        return this.readOnlyValue(this.value);
      }
      return this.readOnlyValue ?? this.value;
    },
  },
  methods: {
    async focus() {
      if (this.computedReadOnly) {
        return;
      }
      if (this.rules == null) {
        this.$refs.unvalidatedSelect.focus();
      } else {
        this.$refs.validatedSelect.focus();
      }
    },
    async validate() {
      await this.$refs.validatedSelect?.validate();
    },
  },
};
</script>
