<template>
  <b-modal ref="modal" scrollable @close="close">
    <template #modal-title>Historique</template>
    <div v-if="historique.length === 0">
      Pas de pr&eacute;c&eacute;dent cultural
    </div>
    <div v-for="parcelle in historique" :key="parcelle.idParcelle" class="mb-3">
      <strong>
        {{ parcelle.codeRecolte }} / {{ parcelle.libelleIlot }} -
        {{ parcelle.libelleParcelle }}
      </strong>
      <div v-if="parcelle.commentaireRetournement">
        <br />
        {{ parcelle.commentaireRetournement }}
      </div>
      <div
        v-for="emb in parcelle.emblavements"
        :key="`${parcelle.idParcelle}_${emb.idEmblavement}`"
        class="pl-2"
      >
        <EtatEmblavementIcon :etat="emb.etat"></EtatEmblavementIcon>
        {{ emb.produit.libelle }} {{ emb.variete.libelle }}
      </div>
    </div>
    <template #modal-footer>
      <b-container fluid>
        <b-row>
          <b-col>
            <b-button block pill variant="outline-primary" @click="close">
              Fermer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import EtatEmblavementIcon from "../icons/EtatEmblavementIcon.vue";
export default {
  name: "HistoriqueParcelleDialog",
  components: { EtatEmblavementIcon },
  data: () => ({
    historique: [],
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show(options = {}) {
      this.historique = options.historique ?? [];
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    close() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
