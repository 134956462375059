<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>
      <template>J'ai sem&eacute; tout...</template>
    </template>
    <p>
      <em>
        Cette fen&ecirc;tre vous permet de valider les semis de plusieurs
        parcelles en une action.
        <br />
        Attention, si vous devez annuler cette action, vous devrez agir parcelle
        par parcelle.
      </em>
    </p>
    <span class="labeled">J'ai terminé tous mes semis de :</span>
    <b-form-radio-group stacked v-model="selected">
      <b-row align-v="baseline">
        <b-col cols="auto">
          <b-form-radio value="CULTURE">Culture</b-form-radio>
        </b-col>
        <b-col>
          <SelectCultureComponent
            v-if="selected === 'CULTURE'"
            ref="selectCulture"
            v-model="culture"
            label=""
            rules="required"
          ></SelectCultureComponent>
        </b-col>
      </b-row>
      <b-row align-v="baseline">
        <b-col cols="auto">
          <b-form-radio value="ESPECE">Esp&egrave;ce</b-form-radio>
        </b-col>
        <b-col>
          <SelectEspeceComponent
            v-if="selected === 'ESPECE'"
            ref="selectEspece"
            v-model="espece"
            :active="true"
            label=""
            rules="required"
          ></SelectEspeceComponent>
        </b-col>
      </b-row>
      <b-row align-v="baseline">
        <b-col cols="auto">
          <b-form-radio value="SAISON">Saisonnalit&eacute;</b-form-radio>
        </b-col>
        <b-col>
          <CustomSelectComponent
            v-if="selected === 'SAISON'"
            ref="selectSaison"
            v-model="saison"
            label=""
            :options="[
              { value: 'AUTOMNE', text: 'Automne' },
              { value: 'PRINTEMPS', text: 'Printemps' },
            ]"
            rules="required"
          ></CustomSelectComponent>
        </b-col>
      </b-row>
    </b-form-radio-group>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import SelectCultureComponent from "../inputs/SelectCultureComponent.vue";
import SelectEspeceComponent from "../inputs/SelectEspeceComponent.vue";
import CustomSelectComponent from "../inputs/CustomSelectComponent.vue";
export default {
  name: "SemisEnMasseDialog",
  components: {
    SelectCultureComponent,
    SelectEspeceComponent,
    CustomSelectComponent,
  },
  data: () => ({
    id: `semis-${uuidv4()}`,
    selected: "CULTURE",
    culture: null,
    espece: null,
    saison: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  methods: {
    show(options) {
      // Récupérer les objets dans le store
      let variete = this.$store.getters["ref/variete"](
        options?.emblavement?.codeVariete
      );
      let produit = this.$store.getters["ref/produitCereale"](
        options?.emblavement?.codeProduit
      );
      let espece = this.$store.getters["ref/espece"](produit?.codeEspece);
      let parcelle = this.$store.getters["decl/parcelle"](
        options?.emblavement?.idParcelle
      );
      // Initialiser le contexte de la modale
      this.selected = "CULTURE";
      this.culture = parcelle?.idCulture ?? null;
      this.espece = espece?.codeEspece ?? null;
      this.saison =
        variete?.periodeSemis ??
        produit?.periodeSemis ??
        espece?.periodeSemis ??
        "AUTOMNE";
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      if (this.selected === "CULTURE") {
        await this.$refs.selectCulture.validate();
      } else if (this.selected === "ESPECE") {
        await this.$refs.selectEspece.validate();
      } else if (this.selected === "SAISON") {
        await this.$refs.selectSaison.validate();
      }
      this.$refs["modal"].hide();
      this.resolvePromise({
        perimetre: this.selected,
        code: this[this.selected.toLowerCase()],
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
