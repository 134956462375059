<template>
  <b-modal
    ref="modal"
    scrollable
    :size="emblavements.length > 1 ? 'lg' : 'md'"
    @close="cancel"
  >
    <template #modal-title>{{ title }}</template>
    <SelectParcellesComponent
      v-if="mode === 'DUPLICATION'"
      ref="selectParcelles"
      description="Seules les parcelles sans emblavement sont sélectionnables."
      :accept="parcellesFilter"
      v-model="parcellesDestination"
    ></SelectParcellesComponent>
    <b-container v-else-if="parcelle" class="context-container">
      <GenericReadonlyComponent label="Parcelle :" :labelCols="{ md: 3 }">
        {{ parcelle.ilot.libelle }} - {{ parcelle.libelle }} (<MontantComponent
          :value="parcelle.surfaceEmblavee - parcelle.surfaceRetiree"
        ></MontantComponent>
        ha)
      </GenericReadonlyComponent>
    </b-container>
    <b-container>
      <b-row>
        <b-col v-if="!postSemis" class="px-1">
          <SelectDeboucheComponent
            label="Débouché :"
            :labelCols="{ md: 6 }"
            :readOnly="readOnly"
            :disabled="postSemis"
            v-model="typeCulture"
            @input="typeCultureChanged"
          ></SelectDeboucheComponent>
          <b-form-group
            label="Mélange d'espèces : "
            label-cols="auto"
            label-cols-md="6"
          >
            <b-form-checkbox
              switch
              :disabled="readOnly || postSemis"
              v-model="melangeEspeces"
              @change="melangeEspecesChanged"
            >
              {{ melangeEspeces ? "oui" : "non" }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(emb, index) in emblavements"
          :key="index"
          :class="`px-1 ${index > 0 ? 'mt-3 mt-md-0' : ''}`"
          cols="12"
          :lg="12 / emblavements.length"
        >
          <h6 v-if="emblavements.length > 1" class="label">
            <strong>Esp&egrave;ce {{ index + 1 }}</strong>
          </h6>
          <section :class="emblavements.length > 1 ? 'composant-melange' : ''">
            <SelectEspeceComponent
              :active="true"
              label="Espèce :"
              :labelCols="{ md: 3 }"
              :readOnly="readOnly"
              :disabled="postSemis"
              v-model="emb.codeEspece"
              @input="especeChanged(emb)"
            ></SelectEspeceComponent>
            <SelectProduitCerealeComponent
              ref="selectProduit"
              :societe="exploitationCourante.codeSociete"
              :typesCulture="typeCulture"
              :espece="emb.codeEspece"
              :actif="true"
              label="Culture :"
              :labelCols="{ md: 3 }"
              :readOnly="readOnly"
              :disabled="postSemis"
              rules="required"
              v-model="emb.codeProduit"
              @input="produitChanged(emb)"
            ></SelectProduitCerealeComponent>
            <SelectVarieteComponent
              ref="selectVariete"
              :societe="exploitationCourante.codeSociete"
              :espece="emb.codeEspece"
              :active="true"
              label="Variété :"
              :labelCols="{ md: 3 }"
              :readOnly="readOnly"
              :disabled="postSemis || varieteDisabled(emb)"
              rules="required"
              v-model="emb.codeVariete"
              @input="varieteChanged(emb)"
            ></SelectVarieteComponent>
            <b-form-group
              label="Mélange de variétés : "
              label-cols="auto"
              label-cols-md="6"
            >
              <b-form-checkbox
                switch
                :disabled="melangeVarietesDisabled(emb)"
                v-model="emb.semeEnMelangeVarietes"
              >
                {{ emb.semeEnMelangeVarietes ? "oui" : "non" }}
              </b-form-checkbox>
            </b-form-group>
            <InputCommentaireComponent
              label="Commentaire variété :"
              :labelCols="{ md: 6 }"
              :showIfEmpty="emb.semeEnMelangeVarietes"
              :readOnly="readOnly"
              :disabled="postMoisson"
              v-model="emb.commentaireVariete"
            ></InputCommentaireComponent>
            <CustomInputNumberComponent
              ref="inputRendement"
              label="Rendement estimé :"
              :labelCols="{ md: 6 }"
              append="t/ha"
              :readOnly="readOnly"
              :disabled="postMoisson"
              :rules="`required|min_value:0|max_value:${
                emb.espece ? emb.espece.rendementMax : 1000.0
              }`"
              v-model="emb.rendementAgriculteur"
            >
            </CustomInputNumberComponent>
            <InputCommentaireComponent
              label="Commentaire emblavement :"
              :labelCols="{ md: 6 }"
              :readOnly="readOnly"
              :disabled="postMoisson"
              v-model="emb.commentaireEmblavement"
            ></InputCommentaireComponent>
          </section>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <ViewEditDialogButtons
        :readOnly="readOnly"
        @edit="mode = 'EDITION'"
        @cancel="cancel"
        @validate="validate"
      ></ViewEditDialogButtons>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import MontantComponent from "../MontantComponent.vue";
import GenericReadonlyComponent from "../inputs/GenericReadonlyComponent.vue";
import SelectParcellesComponent from "../inputs/SelectParcellesComponent.vue";
import SelectDeboucheComponent from "../inputs/SelectDeboucheComponent.vue";
import SelectEspeceComponent from "../inputs/SelectEspeceComponent.vue";
import SelectProduitCerealeComponent from "../inputs/SelectProduitCerealeComponent.vue";
import SelectVarieteComponent from "../inputs/SelectVarieteComponent.vue";
import CustomInputNumberComponent from "../inputs/CustomInputNumberComponent.vue";
import InputCommentaireComponent from "../inputs/InputCommentaireComponent.vue";
import ViewEditDialogButtons from "./ViewEditDialogButtons.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "EmblavementEditionDialog",
  components: {
    MontantComponent,
    GenericReadonlyComponent,
    SelectParcellesComponent,
    SelectDeboucheComponent,
    SelectEspeceComponent,
    SelectProduitCerealeComponent,
    SelectVarieteComponent,
    CustomInputNumberComponent,
    InputCommentaireComponent,
    ViewEditDialogButtons,
  },
  data: () => ({
    id: uuidv4(),
    mode: null,
    parcelle: null,
    parcellesDestination: [],
    typeCulture: null,
    melangeEspeces: false,
    emblavements: [],
    emblavementsASupprimer: [],
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    title() {
      if (this.mode === "CREATION") {
        return "Créer mon emblavement";
      }
      if (this.mode === "EDITION") {
        return "Modifier mon emblavement";
      }
      if (this.mode === "DUPLICATION") {
        return "Dupliquer mon emblavement";
      }
      return "Mon emblavement";
    },
    readOnly() {
      return this.mode === "VISUALISATION";
    },
    postSemis() {
      return this.parcelle && this.parcelle.etatParcelle !== "INTENTION";
    },
    postMoisson() {
      return (
        this.parcelle &&
        this.parcelle.etatParcelle !== "INTENTION" &&
        this.parcelle.etatParcelle !== "SEMEE"
      );
    },
  },
  methods: {
    parcellesFilter(p) {
      return (
        this.$store.getters["decl/emblavementsFromParcelle"](p.id).length <= 0
      );
    },
    // FORMULAIRE DYNAMIQUE
    melangeEspecesChanged() {
      // Ajouter un emblavement si nécessaire
      if (this.melangeEspeces && this.emblavements.length < 2) {
        this.emblavements.push(this.getNouvelEmblavement());
      }

      // Supprimer les emblavements en excédent
      if (!this.melangeEspeces && this.emblavements.length > 1) {
        this.emblavementsASupprimer.push(
          ...this.emblavements
            .splice(1)
            .filter((e) => !!e.id)
            .map((e) => e.id)
        );
      }
    },
    typeCultureChanged() {
      this.emblavements.forEach((emb) => {
        if (
          !emb.produit ||
          !this.typeCulture?.includes(emb.produit.typeCulture)
        ) {
          emb.codeProduit = null;
          emb.produit = null;
        }
      });
    },
    especeChanged(emb) {
      // Récupérer l'espèce correspondante au nouveau code
      emb.espece = this.$store.getters["ref/espece"](emb.codeEspece);

      // Si le produit actuel n'est pas de la même espèce, RAZ
      if (emb.produit?.codeEspece !== emb.codeEspece) {
        emb.codeProduit = null;
        emb.produit = null;
      }

      // Si la variété actuelle n'est pas de la même espèce, RAZ
      if (emb.variete?.codeEspece !== emb.codeEspece) {
        emb.codeVariete = null;
        emb.variete = null;
      }
    },
    produitChanged(emb) {
      // Récupérer le produit correspondant au nouveau code
      emb.produit = this.$store.getters["ref/produitCereale"](emb.codeProduit);

      // Si l'espèce n'est pas déjà initialisée, le faire
      if (!emb.codeEspece) {
        emb.codeEspece = emb.produit?.codeEspece;
        emb.espece = this.$store.getters["ref/espece"](emb.codeEspece);
      }

      // (Ré)initialiser la variété
      emb.codeVariete = emb.produit?.codeVariete;
      emb.variete = this.$store.getters["ref/variete"](emb.codeVariete);

      // Si multiplication, interdire le mélange de variétés
      if (["R1", "G4"].includes(emb.produit?.typeCulture)) {
        emb.semeEnMelangeVarietes = false;
      }

      // (Ré)initialiser le rendement à 0 si le produit n'est pas collecté
      emb.rendementAgriculteur =
        emb.produit && !emb.produit.estCollecte ? 0.0 : null;
    },
    varieteDisabled(emb) {
      return !!emb.produit?.codeVariete;
    },
    melangeVarietesDisabled(emb) {
      return (
        this.readOnly ||
        this.postSemis ||
        ["R1", "G4"].includes(emb.produit?.typeCulture)
      );
    },
    varieteChanged(emb) {
      // Récupérer la variété correspondante au nouveau code
      emb.variete = this.$store.getters["ref/variete"](emb.codeVariete);
    },
    // CYCLE DE VIE DE LA MODALE
    getNouvelEmblavement() {
      return {
        idParcelle: this.parcelle?.id,
        codeEspece: null,
        codeProduit: null,
        codeVariete: null,
        semeEnMelangeVarietes: false,
        commentaireVariete: null,
        rendementAgriculteur: null,
        rendementAjuste: null,
        commentaireEmblavement: null,
      };
    },
    /**
     * Options disponibles :
     * - mode = VISUALISATION, EDITION ou DUPLICATION (VISUALISATION par défaut)
     * - parcelle = parcelle de travail (sauf mode DUPLICATION)
     */
    show(options) {
      this.mode = options?.mode || "VISUALISATION";

      // Soit on fournit une parcelle avec ses emblavements (modes VISUALISATION et EDITION)
      // Soit on fournit des emblavements sans parcelle (mode DUPLICATION)
      this.parcelle = options?.parcelle
        ? UtilsService.deepCopy(options.parcelle)
        : null;
      this.parcellesDestination = [];
      let emblavements =
        this.parcelle?.emblavements ||
        UtilsService.deepCopy(options.emblavements) ||
        [];

      // Ajouter le code espèce dans les emblavements existants
      emblavements.forEach((emb) => {
        emb.codeEspece =
          this.$store.getters["ref/produitCereale"](emb.codeProduit)
            ?.codeEspece || null;
      });

      // Ajouter un emblavement vide si aucun existant
      if (emblavements.length === 0) {
        emblavements.push(this.getNouvelEmblavement());
      }
      this.emblavements = emblavements;

      // Réinitialiser le formulaire
      this.typeCulture = this.$store.getters["ref/typeCultureFromProduit"](
        this.emblavements[0].codeProduit
      )?.codes;
      this.melangeEspeces = this.emblavements.length > 1;
      this.emblavementsASupprimer = [];

      // Ouvrir la popup et renvoyer la promesse
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      // Si mode DUPLICATION, valider les parcelles cibles
      if (this.mode === "DUPLICATION") {
        // TODO this.$refs.selectParcelles.validate();
      }

      // Dans tous les cas, valider les emblavements
      for (let [index] of this.emblavements.entries()) {
        await this.$refs.selectProduit[index].validate();
        await this.$refs.selectVariete[index].validate();
        await this.$refs.inputRendement[index].validate();
      }

      // Fermeture de la popup
      this.$refs["modal"].hide();

      // Si duplication, préparation des emblavements pour envoi à l'API
      if (this.mode === "DUPLICATION") {
        let emblavements = this.parcellesDestination.flatMap((p) =>
          UtilsService.deepCopy(this.emblavements).map((e) => {
            e.id = null;
            e.idParcelle = p.id;
            return e;
          })
        );
        this.emblavements = emblavements;
      }

      // Renvoi du résultat
      this.resolvePromise({
        emblavementsASupprimer: this.emblavementsASupprimer,
        emblavements: this.emblavements,
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>

<style scoped>
.composant-melange {
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
</style>
