<template>
  <section class="d-inline-block">
    <section ref="icon">
      <b-icon-check-circle-fill
        v-if="etat === 'COURANT'"
        variant="success"
      ></b-icon-check-circle-fill>
      <b-icon-x-circle-fill
        v-else-if="etat === 'RETOURNE'"
        variant="warning"
      ></b-icon-x-circle-fill>
      <b-icon-x-circle-fill v-else variant="danger"></b-icon-x-circle-fill>
    </section>
    <b-tooltip
      v-if="etat !== 'COURANT'"
      :target="() => $refs['icon']"
      triggers="hover"
      variant="primary"
    >
      <slot>{{ etat }}</slot>
    </b-tooltip>
  </section>
</template>

<script>
export default {
  name: "EtatEmblavementIcon",
  props: ["etat"],
};
</script>
