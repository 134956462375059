<template>
  <b-tooltip :target="target" triggers="hover" variant="primary">
    Totale :
    <MontantComponent :value="surfaceEmblavee"></MontantComponent>
    ha <br />
    - Abandonn&eacute;e :
    <MontantComponent :value="surfaceRetiree"></MontantComponent>
    ha <br />
    --- <br />
    = Cultiv&eacute;e :
    <MontantComponent :value="surfaceCultivee"></MontantComponent>
    ha <br />
  </b-tooltip>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
export default {
  name: "SurfaceCultiveeTooltip",
  components: { MontantComponent },
  props: ["target", "surfaceEmblavee", "surfaceRetiree"],
  computed: {
    surfaceCultivee() {
      return (this.surfaceEmblavee ?? 0.0) - (this.surfaceRetiree ?? 0.0);
    },
  },
};
</script>
