<template>
  <section class="precedent">
    <template v-if="precedentTelepac">
      {{ precedentTelepac }}
    </template>
    <template v-else-if="precedentHistorique">
      <div
        v-for="emb in precedentHistorique.emblavements"
        :key="emb.idEmblavement"
      >
        {{ emb.produit.libelle }} {{ emb.variete.libelle }}
      </div>
      <b-link v-if="historique.length > 1" href="#" @click="openHistorique">
        Voir plus
      </b-link>
      <HistoriqueParcelleDialog
        ref="historiqueDialog"
      ></HistoriqueParcelleDialog>
    </template>
    <template v-else>Pas de pr&eacute;c&eacute;dent cultural</template>
  </section>
</template>

<script>
import HistoriqueParcelleDialog from "../dialogs/HistoriqueParcelleDialog.vue";
import DeclarationsService from "../../services/declarations.service";
export default {
  name: "HistoriqueParcelleComponent",
  components: { HistoriqueParcelleDialog },
  props: ["idParcelle"],
  watch: {
    idParcelle: "load",
  },
  data: () => ({
    historique: [],
  }),
  computed: {
    parcelle() {
      return this.$store.getters["decl/parcelle"](this.idParcelle);
    },
    precedentTelepac() {
      return this.parcelle?.precedentTelepac;
    },
    precedentHistorique() {
      return this.historique[0];
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.historique = [];

      // Si on a un précédent Télépac
      if (this.precedentTelepac) {
        return;
      }

      // Sinon charger l'historique
      let response = await DeclarationsService.getHistoriqueParcelle(
        this.idParcelle
      );
      this.historique = response.data.map((p) => {
        p.emblavements = p.emblavements.map((e) => {
          e.produit = this.$store.getters["ref/produitCereale"](e.codeProduit);
          e.variete = this.$store.getters["ref/variete"](e.codeVariete);
          return e;
        });
        return p;
      });
    },
    openHistorique() {
      this.$refs.historiqueDialog.show({
        historique: this.historique,
      });
    },
  },
};
</script>
